





























import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { FormField } from "@/components/form-components/types";
import { chain } from "lodash";

@Component({ components: { Multiselect }, inheritAttrs: false })
export default class djlMultiSelect extends Vue implements FormField {
  @Prop({ required: true, type: String })
  readonly name!: string;

  @Prop({ type: Boolean, default: false })
  private required!: boolean;

  @ModelSync("input", "changed")
  private values!: any;

  @Prop()
  private sortBy!: string;

  isValid: boolean = true;

  validityChecked: boolean = false;

  $attrs: any;
  $refs: any;
  $listeners: any;

  get computedName() {
    return this.name.toLowerCase().split(" ").join("-");
  }

  get sortedOptions() {
    return this.sortBy
      ? chain(this.$attrs.options).sortBy(this.sortBy).value()
      : this.$attrs.options;
  }

  validate() {
    if (this.required) {
      const value = this.values;

      if (!value) {
        this.isValid = false;
      } else {
        if (Array.isArray(value)) {
          this.isValid = value.length > 0;
        } else {
          this.isValid = true;
        }
      }
    } else {
      this.isValid = true;
    }
    this.validityChecked = true;
    return this.isValid;
  }

  resetValue() {
    this.values = null;
  }

  onOpen() {
    this.validityChecked = false;
  }
}
